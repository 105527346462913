'use client'

import React from "react";
import ErrorPage from "@/app/components/ErrorPage";
import Button from "@/app/pwa/components/Button";

export default function Error({
                                error,
                                reset,
                              }: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  // useEffect(() => {
  //   // Log the error to an error reporting service
  //   console.error(error)
  // }, [error])

  return <div className="flex justify-center items-center text-center h-[50dvh] max-w-basic-page">
    <div className="space-y-4">
      <h2 className="">{"Leider ist ein Fehler aufgetreten"}</h2>
      <Button color="primary" onClick={() => reset()}>{"Nochmal versuchen"}</Button>
    </div>
  </div>
}
